import React from "react";
import styles from "./styles.module.css";

type InputTextType = {
  disabled?: boolean;
  label: string;
  className?: string;
  id?: string;
  inputValue?: string;
  onInputChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  error?: string;
  possibleValues?: { name: string; code: string }[];
};

const InputText: React.FC<InputTextType> = (props) => {
  const {
    id,
    label,
    className,
    disabled,
    error,
    inputValue,
    onInputChange,
    possibleValues,
  } = props;

  return (
    <div className={`${styles["input"]} ${className}`}>
      {label && (
        <label>
          <span className={styles["label__title"]}>{label}</span>
        </label>
      )}
      <div
        className={`${styles["input__type"]} ${
          styles["input__type--number"]
        }  ${
          error || error === "" ? styles["input__type--number--error"] : ""
        } ${disabled ? styles["input__type--number--disabled"] : ""}`}
      >
        {inputValue !== undefined &&
          onInputChange &&
          (possibleValues ? (
            <select value={inputValue} onChange={onInputChange}>
              {possibleValues.map(({ name, code }) => (
                <option value={code} key={code}>
                  {name}
                </option>
              ))}
            </select>
          ) : (
            <input
              value={inputValue}
              onChange={onInputChange}
              type="text"
            ></input>
          ))}
        <span
          className={`${styles["input__type__child"]} ${styles.symbol}`}
          style={{ height: "100%" }}
          id={id}
        ></span>
      </div>
      <span className={`${styles["text-error"]}`}>{error}</span>
    </div>
  );
};

InputText.defaultProps = {
  label: "\u00A0",
  className: "",
  disabled: false,
};

export default InputText;
