function base64ToJson(base64token: string) {
  return JSON.parse(atob(base64token));
}

export function getParam(name: string, defaultValue?: string): string {
  const value = new URLSearchParams(window.location.search).get(name);
  if (value === null) {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (window.location.hostname === "localhost") {
      return "e30="; // '{}' encoded in base64
    } else {
      throw new Error(
        `Parameter ${name} has not been provided in the query string`
      );
    }
  }
  return value;
}

export interface CustomerAddress {
  country: string;
  postCode: string;
  state: null | string;
  street: string;
  subStreet: null | string;
  town: string;
}

export function getCustomerAddress(): CustomerAddress {
  const rawCustomerAddress = getParam("customerAddress");
  try {
    return base64ToJson(rawCustomerAddress) as CustomerAddress;
  } catch (e) {
    throw new Error("Query string param 'customerAddress' is incorrect");
  }
}
