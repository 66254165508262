type DateString = string; // eg: "2020-08-20T23:04:10.668Z"
export interface SubmitResponse {
  billingAddress: {
    country: string;
    postCode: string;
    state: null | string;
    street: string;
    subStreet: string;
    town: string;
  };
  bin: string;
  brand: string;
  createdAt: DateString;
  expiresAt: DateString;
  expiryMonth: number;
  expiryYear: number;
  id: string | undefined;
  lastDigits: string;
  updatedAt: DateString;
}

export function onFormSubmit(
  transactionId: string,
  onFailure: (err: string) => void
) {
  return (status: number, response: SubmitResponse) => {
    const ccTokenId = response.id;
    if (status >= 300 || ccTokenId === undefined) {
      //window.alert("Card has been rejected, please contact support@onramper.com with the details.") //TODO: Improve error here
      const errorMessage =
        "Card has been rejected, please contact support@onramper.com with the details.";
      window.parent.postMessage(errorMessage, "*");
      onFailure(errorMessage);
    } else {
      // 'W10=' is the base64 encoding of '[]'
      window.parent.postMessage({
        gateway: "Moonpay",
        type: "card-completed",
        transactionId,
        ccTokenId,
      }, "*");
    }
  };
}
