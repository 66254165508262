import MoonPay from "@moonpay/browser";

const moonpayPublishableKey =
  process.env.REACT_APP_STAGE === "prod" ? "pk_live_MJouyH3Fe3BVaNOaHCt5SvBfJ5Mk7RvQ" : "pk_test_PjABKr88VlgosyTueq3exrVnYYLd4ZB";

interface FormObject {
  errorMessages: string[];
  isDirty: boolean;
  isEmpty: boolean;
  isFocused: boolean;
  isValid: boolean;
  cardType?: string;
  last4?: string | null;
  bin?: string | null;
  name: string;
}

export interface FormState {
  cvc: FormObject;
  expiryDate: FormObject;
  number: FormObject;
}

export function isFormValid(formState: FormState) {
  return Object.values(formState).every((obj: FormObject) => obj.isValid);
}

export function initialize(
  customerId: string,
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
) {
  MoonPay.initialize(moonpayPublishableKey, customerId);
  MoonPay.trackPageView();
  const form = MoonPay.createCardDetailsForm((state: FormState) => {
    // First few calls are just new fields being added, ignore them
    if (state.cvc && state.expiryDate && state.number) {
      setFormState(state);
    }
  });
  const css = {
    fontFamily:
      "system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "0.875rem",
    lineHeight: "18px",
  };

  form.createField("#cc-number", {
    type: "card-number",
    name: "number",
    css,
    placeholder: "4111 1111 1111 1111",
    validations: ["required", "validCardNumber"],
  });
  form.createField("#cc-expiration", {
    type: "card-expiration-date",
    name: "expiryDate",
    css,
    placeholder: "01 / 23",
    validations: ["required", "validCardExpirationDate"],
  });

  form.createField("#cc-cvc", {
    type: "card-security-code",
    name: "cvc",
    css,
    placeholder: "123",
    validations: ["required", "validCardSecurityCode"],
  });

  return form;
}

export function getErrors(formObject: FormObject): string | undefined {
  if (!formObject.isDirty || formObject.isFocused) {
    return undefined;
  } else if (formObject.isEmpty) {
    return "Must not be empty";
  } else if (!formObject.isValid) {
    return "Invalid input";
  }
}

const defaultFormObject = {
  errorMessages: [],
  isDirty: false,
  isEmpty: true,
  isFocused: false,
  isValid: false,
  name: "",
};

export const defaultFormState = {
  cvc: defaultFormObject,
  expiryDate: defaultFormObject,
  number: defaultFormObject,
};
