export const USstates = [
  {
    code: "AL",
    name: "Alabama",
  },
  {
    code: "AK",
    name: "Alaska",
  },
  {
    code: "AZ",
    name: "Arizona",
  },
  {
    code: "AR",
    name: "Arkansas",
  },
  {
    code: "CA",
    name: "California",
  },
  {
    code: "CO",
    name: "Colorado",
  },
  {
    code: "DE",
    name: "Delaware",
  },
  {
    code: "DC",
    name: "District of Columbia",
  },
  {
    code: "FL",
    name: "Florida",
  },
  {
    code: "GA",
    name: "Georgia",
  },
  {
    code: "ID",
    name: "Idaho",
  },
  {
    code: "IL",
    name: "Illinois",
  },
  {
    code: "IN",
    name: "Indiana",
  },
  {
    code: "IA",
    name: "Iowa",
  },
  {
    code: "LA",
    name: "Louisiana",
  },
  {
    code: "ME",
    name: "Maine",
  },
  {
    code: "MD",
    name: "Maryland",
  },
  {
    code: "MA",
    name: "Massachusetts",
  },
  {
    code: "MI",
    name: "Michigan",
  },
  {
    code: "MN",
    name: "Minnesota",
  },
  {
    code: "MS",
    name: "Mississippi",
  },
  {
    code: "MO",
    name: "Missouri",
  },
  {
    code: "MT",
    name: "Montana",
  },
  {
    code: "NV",
    name: "Nevada",
  },
  {
    code: "NH",
    name: "New Hampshire",
  },
  {
    code: "NJ",
    name: "New Jersey",
  },
  {
    code: "NC",
    name: "North Carolina",
  },
  {
    code: "ND",
    name: "North Dakota",
  },
  {
    code: "OH",
    name: "Ohio",
  },
  {
    code: "OK",
    name: "Oklahoma",
  },
  {
    code: "OR",
    name: "Oregon",
  },
  {
    code: "PA",
    name: "Pennsylvania",
  },
  {
    code: "PR",
    name: "Puerto Rico",
  },
  {
    code: "SC",
    name: "South Carolina",
  },
  {
    code: "SD",
    name: "South Dakota",
  },
  {
    code: "TN",
    name: "Tennessee",
  },
  {
    code: "TX",
    name: "Texas",
  },
  {
    code: "UT",
    name: "Utah",
  },
  {
    code: "VT",
    name: "Vermont",
  },
  {
    code: "VA",
    name: "Virginia",
  },
  {
    code: "WA",
    name: "Washington",
  },
  {
    code: "WV",
    name: "West Virginia",
  },
  {
    code: "WI",
    name: "Wisconsin",
  },
  {
    code: "WY",
    name: "Wyoming",
  },
];

// Generated with
// JSON.stringify(a.map(({alpha3, name})=>({code:alpha3, name})))
// Applied to the response of /countries on moonpay's API
export const countries = [
  { code: "AFG", name: "Afghanistan" },
  { code: "DZA", name: "Algeria" },
  { code: "AND", name: "Andorra" },
  { code: "AGO", name: "Angola" },
  { code: "ATG", name: "Antigua and Barbuda" },
  { code: "ARG", name: "Argentina" },
  { code: "ARM", name: "Armenia" },
  { code: "AUS", name: "Australia" },
  { code: "AUT", name: "Austria" },
  { code: "AZE", name: "Azerbaijan" },
  { code: "BHR", name: "Bahrain" },
  { code: "BLR", name: "Belarus" },
  { code: "BEL", name: "Belgium" },
  { code: "BLZ", name: "Belize" },
  { code: "BEN", name: "Benin" },
  { code: "BTN", name: "Bhutan" },
  { code: "BIH", name: "Bosnia and Herzegovina" },
  { code: "BRA", name: "Brazil" },
  { code: "BRN", name: "Brunei Darussalam" },
  { code: "BGR", name: "Bulgaria" },
  { code: "BFA", name: "Burkina Faso" },
  { code: "BDI", name: "Burundi" },
  { code: "CPV", name: "Cabo Verde" },
  { code: "CMR", name: "Cameroon" },
  { code: "CAN", name: "Canada" },
  { code: "CAF", name: "Central African Republic" },
  { code: "TCD", name: "Chad" },
  { code: "CHL", name: "Chile" },
  { code: "COL", name: "Colombia" },
  { code: "COG", name: "Congo" },
  { code: "COD", name: "Congo, Democratic Republic of the" },
  { code: "CRI", name: "Costa Rica" },
  { code: "CIV", name: "Côte d'Ivoire" },
  { code: "HRV", name: "Croatia" },
  { code: "CYP", name: "Cyprus" },
  { code: "CZE", name: "Czechia" },
  { code: "DNK", name: "Denmark" },
  { code: "DJI", name: "Djibouti" },
  { code: "DMA", name: "Dominica" },
  { code: "DOM", name: "Dominican Republic" },
  { code: "EGY", name: "Egypt" },
  { code: "SLV", name: "El Salvador" },
  { code: "GNQ", name: "Equatorial Guinea" },
  { code: "EST", name: "Estonia" },
  { code: "ETH", name: "Ethiopia" },
  { code: "FJI", name: "Fiji" },
  { code: "FIN", name: "Finland" },
  { code: "FRA", name: "France" },
  { code: "GAB", name: "Gabon" },
  { code: "GMB", name: "Gambia" },
  { code: "GEO", name: "Georgia" },
  { code: "DEU", name: "Germany" },
  { code: "GRC", name: "Greece" },
  { code: "GRD", name: "Grenada" },
  { code: "GTM", name: "Guatemala" },
  { code: "GIN", name: "Guinea" },
  { code: "GNB", name: "Guinea-Bissau" },
  { code: "GUY", name: "Guyana" },
  { code: "HTI", name: "Haiti" },
  { code: "HND", name: "Honduras" },
  { code: "HKG", name: "Hong Kong" },
  { code: "HUN", name: "Hungary" },
  { code: "IND", name: "India" },
  { code: "IDN", name: "Indonesia" },
  { code: "IRQ", name: "Iraq" },
  { code: "IRL", name: "Ireland" },
  { code: "ISR", name: "Israel" },
  { code: "ITA", name: "Italy" },
  { code: "JPN", name: "Japan" },
  { code: "JOR", name: "Jordan" },
  { code: "KAZ", name: "Kazakhstan" },
  { code: "KEN", name: "Kenya" },
  { code: "XKX", name: "Kosovo" },
  { code: "KOR", name: "Korea, Republic of" },
  { code: "KWT", name: "Kuwait" },
  { code: "LAO", name: "Lao People's Democratic Republic" },
  { code: "LVA", name: "Latvia" },
  { code: "LBN", name: "Lebanon" },
  { code: "LSO", name: "Lesotho" },
  { code: "LBR", name: "Liberia" },
  { code: "LBY", name: "Libya" },
  { code: "LIE", name: "Liechtenstein" },
  { code: "LTU", name: "Lithuania" },
  { code: "LUX", name: "Luxembourg" },
  { code: "MDG", name: "Madagascar" },
  { code: "MWI", name: "Malawi" },
  { code: "MYS", name: "Malaysia" },
  { code: "MDV", name: "Maldives" },
  { code: "MLI", name: "Mali" },
  { code: "MLT", name: "Malta" },
  { code: "MHL", name: "Marshall Islands" },
  { code: "MRT", name: "Mauritania" },
  { code: "MEX", name: "Mexico" },
  { code: "FSM", name: "Micronesia (Federated States of)" },
  { code: "MDA", name: "Moldova, Republic of" },
  { code: "MCO", name: "Monaco" },
  { code: "MNE", name: "Montenegro" },
  { code: "MAR", name: "Morocco" },
  { code: "MOZ", name: "Mozambique" },
  { code: "NAM", name: "Namibia" },
  { code: "NRU", name: "Nauru" },
  { code: "NPL", name: "Nepal" },
  { code: "NLD", name: "Netherlands" },
  { code: "NZL", name: "New Zealand" },
  { code: "NER", name: "Niger" },
  { code: "NGA", name: "Nigeria" },
  { code: "MKD", name: "North Macedonia" },
  { code: "NOR", name: "Norway" },
  { code: "OMN", name: "Oman" },
  { code: "PLW", name: "Palau" },
  { code: "PSE", name: "Palestine, State of" },
  { code: "PNG", name: "Papua New Guinea" },
  { code: "PRY", name: "Paraguay" },
  { code: "PER", name: "Peru" },
  { code: "PHL", name: "Philippines" },
  { code: "POL", name: "Poland" },
  { code: "PRT", name: "Portugal" },
  { code: "QAT", name: "Qatar" },
  { code: "ROU", name: "Romania" },
  { code: "RUS", name: "Russian Federation" },
  { code: "RWA", name: "Rwanda" },
  { code: "KNA", name: "Saint Kitts and Nevis" },
  { code: "LCA", name: "Saint Lucia" },
  { code: "WSM", name: "Samoa" },
  { code: "SMR", name: "San Marino" },
  { code: "STP", name: "Sao Tome and Principe" },
  { code: "SAU", name: "Saudi Arabia" },
  { code: "SEN", name: "Senegal" },
  { code: "SRB", name: "Serbia" },
  { code: "SYC", name: "Seychelles" },
  { code: "SLE", name: "Sierra Leone" },
  { code: "SGP", name: "Singapore" },
  { code: "SVK", name: "Slovakia" },
  { code: "SVN", name: "Slovenia" },
  { code: "SLB", name: "Solomon Islands" },
  { code: "SOM", name: "Somalia" },
  { code: "ZAF", name: "South Africa" },
  { code: "ESP", name: "Spain" },
  { code: "LKA", name: "Sri Lanka" },
  { code: "SUR", name: "Suriname" },
  { code: "SWE", name: "Sweden" },
  { code: "CHE", name: "Switzerland" },
  { code: "TWN", name: "Taiwan" },
  { code: "TJK", name: "Tajikistan" },
  { code: "TZA", name: "Tanzania, United Republic of" },
  { code: "THA", name: "Thailand" },
  { code: "TGO", name: "Togo" },
  { code: "TON", name: "Tonga" },
  { code: "TTO", name: "Trinidad and Tobago" },
  { code: "TUN", name: "Tunisia" },
  { code: "TUR", name: "Turkey" },
  { code: "TKM", name: "Turkmenistan" },
  { code: "UKR", name: "Ukraine" },
  { code: "ARE", name: "United Arab Emirates" },
  { code: "GBR", name: "United Kingdom" },
  { code: "USA", name: "United States of America" },
  { code: "URY", name: "Uruguay" },
  { code: "UZB", name: "Uzbekistan" },
  { code: "VAT", name: "Vatican City" },
  { code: "VNM", name: "Vietnam" },
  { code: "ESH", name: "Western Sahara" },
  { code: "ZMB", name: "Zambia" },
];
